import { api } from '~/constants/api'

export default () => {
  const { messageError } = useSwal()
  const { t } = useNuxtApp().$i18n

  const doFetch = async ({ type = '', input, method = 'GET', body, params = {}, options = {} }) => {
    return await $fetch(api[type](input), { method, params, body }).catch(async (e) => {
      if (options?.silent) {
        console.error(e)
      } else {
        await messageError(t('ajax.fail'), e.data.statusCode === 401 ? t('ajax.needLogin') : e.data.statusMessage || t('ajax.info'))
      }
      return false
    })
  }

  return {
    doFetch,
  }
}
