import { default as aboutfWw076eFUeMeta } from "/vercel/path0/BottleNekoV2/pages/about.vue?macro=true";
import { default as contactlNZzYzZOUwMeta } from "/vercel/path0/BottleNekoV2/pages/contact.vue?macro=true";
import { default as daily1NCsWAfoQEMeta } from "/vercel/path0/BottleNekoV2/pages/daily.vue?macro=true";
import { default as _91code_93gDjG5hDUWRMeta } from "/vercel/path0/BottleNekoV2/pages/deck/[code].vue?macro=true";
import { default as index9ivm9oQXaWMeta } from "/vercel/path0/BottleNekoV2/pages/deck/index.vue?macro=true";
import { default as _91code_93uGOMJVVL39Meta } from "/vercel/path0/BottleNekoV2/pages/decklog/[code].vue?macro=true";
import { default as demoSJvCaihgAlMeta } from "/vercel/path0/BottleNekoV2/pages/demo.vue?macro=true";
import { default as eula4ffo62PR0MMeta } from "/vercel/path0/BottleNekoV2/pages/eula.vue?macro=true";
import { default as hero8TkaF1c9j6Meta } from "/vercel/path0/BottleNekoV2/pages/hero.vue?macro=true";
import { default as indexBQeCvNj2mEMeta } from "/vercel/path0/BottleNekoV2/pages/index.vue?macro=true";
import { default as loginfZCxizYhcaMeta } from "/vercel/path0/BottleNekoV2/pages/login.vue?macro=true";
import { default as _91id_93jgLjpoMsoHMeta } from "/vercel/path0/BottleNekoV2/pages/member/[id].vue?macro=true";
import { default as notificationAerQElUxnEMeta } from "/vercel/path0/BottleNekoV2/pages/notification.vue?macro=true";
import { default as policyfdE92SMNy4Meta } from "/vercel/path0/BottleNekoV2/pages/policy.vue?macro=true";
import { default as productwWH14BIjhEMeta } from "/vercel/path0/BottleNekoV2/pages/product.vue?macro=true";
import { default as qaaVLVxtzlguMeta } from "/vercel/path0/BottleNekoV2/pages/qa.vue?macro=true";
import { default as _91id_93qK5Z4L9LzxMeta } from "/vercel/path0/BottleNekoV2/pages/series/[id].vue?macro=true";
import { default as indexc1nOy9PiktMeta } from "/vercel/path0/BottleNekoV2/pages/series/index.vue?macro=true";
import { default as editcgBk4zSWlrMeta } from "/vercel/path0/BottleNekoV2/pages/social/[code]/edit.vue?macro=true";
import { default as indexRJ5HYyzSFWMeta } from "/vercel/path0/BottleNekoV2/pages/social/[code]/index.vue?macro=true";
import { default as addUvhqQdI8TSMeta } from "/vercel/path0/BottleNekoV2/pages/social/add.vue?macro=true";
import { default as indexm9SHZvj929Meta } from "/vercel/path0/BottleNekoV2/pages/social/index.vue?macro=true";
import { default as my4nKq5B1QVfMeta } from "/vercel/path0/BottleNekoV2/pages/social/my.vue?macro=true";
import { default as _91code_9300LSvN1WQDMeta } from "/vercel/path0/BottleNekoV2/pages/social/topic/[code].vue?macro=true";
import { default as userLE2mpWQKt6Meta } from "/vercel/path0/BottleNekoV2/pages/user.vue?macro=true";
import { default as workshop9RgwILlOBfMeta } from "/vercel/path0/BottleNekoV2/pages/workshop.vue?macro=true";
export default [
  {
    name: aboutfWw076eFUeMeta?.name ?? "about",
    path: aboutfWw076eFUeMeta?.path ?? "/about",
    meta: aboutfWw076eFUeMeta || {},
    alias: aboutfWw076eFUeMeta?.alias || [],
    redirect: aboutfWw076eFUeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactlNZzYzZOUwMeta?.name ?? "contact",
    path: contactlNZzYzZOUwMeta?.path ?? "/contact",
    meta: contactlNZzYzZOUwMeta || {},
    alias: contactlNZzYzZOUwMeta?.alias || [],
    redirect: contactlNZzYzZOUwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: daily1NCsWAfoQEMeta?.name ?? "daily",
    path: daily1NCsWAfoQEMeta?.path ?? "/daily",
    meta: daily1NCsWAfoQEMeta || {},
    alias: daily1NCsWAfoQEMeta?.alias || [],
    redirect: daily1NCsWAfoQEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/daily.vue").then(m => m.default || m)
  },
  {
    name: _91code_93gDjG5hDUWRMeta?.name ?? "deck-code",
    path: _91code_93gDjG5hDUWRMeta?.path ?? "/deck/:code()",
    meta: _91code_93gDjG5hDUWRMeta || {},
    alias: _91code_93gDjG5hDUWRMeta?.alias || [],
    redirect: _91code_93gDjG5hDUWRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/deck/[code].vue").then(m => m.default || m)
  },
  {
    name: index9ivm9oQXaWMeta?.name ?? "deck",
    path: index9ivm9oQXaWMeta?.path ?? "/deck",
    meta: index9ivm9oQXaWMeta || {},
    alias: index9ivm9oQXaWMeta?.alias || [],
    redirect: index9ivm9oQXaWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/deck/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93uGOMJVVL39Meta?.name ?? "decklog-code",
    path: _91code_93uGOMJVVL39Meta?.path ?? "/decklog/:code()",
    meta: _91code_93uGOMJVVL39Meta || {},
    alias: _91code_93uGOMJVVL39Meta?.alias || [],
    redirect: _91code_93uGOMJVVL39Meta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/decklog/[code].vue").then(m => m.default || m)
  },
  {
    name: demoSJvCaihgAlMeta?.name ?? "demo",
    path: demoSJvCaihgAlMeta?.path ?? "/demo",
    meta: demoSJvCaihgAlMeta || {},
    alias: demoSJvCaihgAlMeta?.alias || [],
    redirect: demoSJvCaihgAlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: eula4ffo62PR0MMeta?.name ?? "eula",
    path: eula4ffo62PR0MMeta?.path ?? "/eula",
    meta: eula4ffo62PR0MMeta || {},
    alias: eula4ffo62PR0MMeta?.alias || [],
    redirect: eula4ffo62PR0MMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/eula.vue").then(m => m.default || m)
  },
  {
    name: hero8TkaF1c9j6Meta?.name ?? "hero",
    path: hero8TkaF1c9j6Meta?.path ?? "/hero",
    meta: hero8TkaF1c9j6Meta || {},
    alias: hero8TkaF1c9j6Meta?.alias || [],
    redirect: hero8TkaF1c9j6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/hero.vue").then(m => m.default || m)
  },
  {
    name: indexBQeCvNj2mEMeta?.name ?? "index",
    path: indexBQeCvNj2mEMeta?.path ?? "/",
    meta: indexBQeCvNj2mEMeta || {},
    alias: indexBQeCvNj2mEMeta?.alias || [],
    redirect: indexBQeCvNj2mEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginfZCxizYhcaMeta?.name ?? "login",
    path: loginfZCxizYhcaMeta?.path ?? "/login",
    meta: loginfZCxizYhcaMeta || {},
    alias: loginfZCxizYhcaMeta?.alias || [],
    redirect: loginfZCxizYhcaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jgLjpoMsoHMeta?.name ?? "member-id",
    path: _91id_93jgLjpoMsoHMeta?.path ?? "/member/:id()",
    meta: _91id_93jgLjpoMsoHMeta || {},
    alias: _91id_93jgLjpoMsoHMeta?.alias || [],
    redirect: _91id_93jgLjpoMsoHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/member/[id].vue").then(m => m.default || m)
  },
  {
    name: notificationAerQElUxnEMeta?.name ?? "notification",
    path: notificationAerQElUxnEMeta?.path ?? "/notification",
    meta: notificationAerQElUxnEMeta || {},
    alias: notificationAerQElUxnEMeta?.alias || [],
    redirect: notificationAerQElUxnEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/notification.vue").then(m => m.default || m)
  },
  {
    name: policyfdE92SMNy4Meta?.name ?? "policy",
    path: policyfdE92SMNy4Meta?.path ?? "/policy",
    meta: policyfdE92SMNy4Meta || {},
    alias: policyfdE92SMNy4Meta?.alias || [],
    redirect: policyfdE92SMNy4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: productwWH14BIjhEMeta?.name ?? "product",
    path: productwWH14BIjhEMeta?.path ?? "/product",
    meta: productwWH14BIjhEMeta || {},
    alias: productwWH14BIjhEMeta?.alias || [],
    redirect: productwWH14BIjhEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/product.vue").then(m => m.default || m)
  },
  {
    name: qaaVLVxtzlguMeta?.name ?? "qa",
    path: qaaVLVxtzlguMeta?.path ?? "/qa",
    meta: qaaVLVxtzlguMeta || {},
    alias: qaaVLVxtzlguMeta?.alias || [],
    redirect: qaaVLVxtzlguMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/qa.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qK5Z4L9LzxMeta?.name ?? "series-id",
    path: _91id_93qK5Z4L9LzxMeta?.path ?? "/series/:id()",
    meta: _91id_93qK5Z4L9LzxMeta || {},
    alias: _91id_93qK5Z4L9LzxMeta?.alias || [],
    redirect: _91id_93qK5Z4L9LzxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/series/[id].vue").then(m => m.default || m)
  },
  {
    name: indexc1nOy9PiktMeta?.name ?? "series",
    path: indexc1nOy9PiktMeta?.path ?? "/series",
    meta: indexc1nOy9PiktMeta || {},
    alias: indexc1nOy9PiktMeta?.alias || [],
    redirect: indexc1nOy9PiktMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/series/index.vue").then(m => m.default || m)
  },
  {
    name: editcgBk4zSWlrMeta?.name ?? "social-code-edit",
    path: editcgBk4zSWlrMeta?.path ?? "/social/:code()/edit",
    meta: editcgBk4zSWlrMeta || {},
    alias: editcgBk4zSWlrMeta?.alias || [],
    redirect: editcgBk4zSWlrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/social/[code]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexRJ5HYyzSFWMeta?.name ?? "social-code",
    path: indexRJ5HYyzSFWMeta?.path ?? "/social/:code()",
    meta: indexRJ5HYyzSFWMeta || {},
    alias: indexRJ5HYyzSFWMeta?.alias || [],
    redirect: indexRJ5HYyzSFWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/social/[code]/index.vue").then(m => m.default || m)
  },
  {
    name: addUvhqQdI8TSMeta?.name ?? "social-add",
    path: addUvhqQdI8TSMeta?.path ?? "/social/add",
    meta: addUvhqQdI8TSMeta || {},
    alias: addUvhqQdI8TSMeta?.alias || [],
    redirect: addUvhqQdI8TSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/social/add.vue").then(m => m.default || m)
  },
  {
    name: indexm9SHZvj929Meta?.name ?? "social",
    path: indexm9SHZvj929Meta?.path ?? "/social",
    meta: indexm9SHZvj929Meta || {},
    alias: indexm9SHZvj929Meta?.alias || [],
    redirect: indexm9SHZvj929Meta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/social/index.vue").then(m => m.default || m)
  },
  {
    name: my4nKq5B1QVfMeta?.name ?? "social-my",
    path: my4nKq5B1QVfMeta?.path ?? "/social/my",
    meta: my4nKq5B1QVfMeta || {},
    alias: my4nKq5B1QVfMeta?.alias || [],
    redirect: my4nKq5B1QVfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/social/my.vue").then(m => m.default || m)
  },
  {
    name: _91code_9300LSvN1WQDMeta?.name ?? "social-topic-code",
    path: _91code_9300LSvN1WQDMeta?.path ?? "/social/topic/:code()",
    meta: _91code_9300LSvN1WQDMeta || {},
    alias: _91code_9300LSvN1WQDMeta?.alias || [],
    redirect: _91code_9300LSvN1WQDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/social/topic/[code].vue").then(m => m.default || m)
  },
  {
    name: userLE2mpWQKt6Meta?.name ?? "user",
    path: userLE2mpWQKt6Meta?.path ?? "/user",
    meta: userLE2mpWQKt6Meta || {},
    alias: userLE2mpWQKt6Meta?.alias || [],
    redirect: userLE2mpWQKt6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/user.vue").then(m => m.default || m)
  },
  {
    name: workshop9RgwILlOBfMeta?.name ?? "workshop",
    path: workshop9RgwILlOBfMeta?.path ?? "/workshop",
    meta: workshop9RgwILlOBfMeta || {},
    alias: workshop9RgwILlOBfMeta?.alias || [],
    redirect: workshop9RgwILlOBfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/BottleNekoV2/pages/workshop.vue").then(m => m.default || m)
  }
]