import moment from 'moment'
import * as _ from 'lodash'

export default () => {
  const formatTime = (input = new Date(), format = 'YYYY-MM-DD', { inputFormat } = {}) => {
    const date = moment(input, inputFormat).format(format)

    return date === 'Invalid date' ? '' : date
  }

  const counter = (n = 0) => {
    return new Array(n).fill(0).map((e, i) => i)
  }

  const sleep = async (ms) => {
    await new Promise((resolve, reject) => {
      setTimeout(() => resolve(), ms)
    })
  }

  const filterSort = (filter, sort) => {
    if (filter.sort[0].field === sort.field) {
      filter.sort[0].order = filter.sort[0].order === 'asc' ? 'desc' : 'asc'
    } else {
      filter.sort[0].order = 'asc'
      filter.sort[0].field = sort.field
    }

    return filter
  }

  return {
    filterSort,
    formatTime,
    counter,
    sleep,
  }
}
