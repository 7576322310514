import { api } from '~/constants/api'
import { colors } from '~/constants/colors'

export const useSeriesStore = defineStore(
  'series',
  () => {
    // seires list
    const series = ref([])
    const seriesFilters = ref({ keyword: '', sort: [{ field: 'sell', order: 'desc' }] })

    // series history
    const seriesIdHistory = ref([])
    const seriesHistory = computed(() => seriesIdHistory.value.map((e) => series.value.find((s) => s.id === e)))

    const selectSeries = (id) => {
      seriesIdHistory.value = [id, ...seriesIdHistory.value]
        .filter((e) => e)
        .filter((e, i, a) => a.indexOf(e) === i)
        .slice(0, 7)
      nowSeriesId.value = id
    }

    // now series
    const nowSeriesId = ref()
    const nowSeries = computed(() => series.value.find((e) => e.id === nowSeriesId.value))
    const nowSeriesAjax = ref({})
    const nowSeriesColor = computed(() => (nowSeriesId.value ? colors[nowSeriesId.value % colors.length] : '#000000'))

    // ajax
    const getSeries = async () => {
      const { data } = await useFetch(api['series'](), {
        key: 'series',
      })
      if (data.value) {
        series.value = data.value
      }
    }

    return {
      series,
      seriesFilters,

      seriesIdHistory,
      seriesHistory,
      selectSeries,

      nowSeriesId,
      nowSeries,
      nowSeriesAjax,
      nowSeriesColor,

      getSeries,
    }
  },
  {
    persist: {
      storage: persistedState.localStorage,
      paths: ['series', 'seriesIdHistory'],
      afterRestore: (ctx) => {
        console.log(`just restored '${ctx.store.$id}'`)
      },
    },
  }
)
