<template>
  <NuxtLayout>
    <UIOffline v-if="!isOnline" />
    <NuxtPage v-else />
  </NuxtLayout>
</template>

<script setup>
import { useOnline } from '@vueuse/core'
import metaData from '@/config/manifest.json'

const { getSeries } = useSeriesStore()
const { getMyDecks } = useDeckStore()
const globalStore = useGlobalStore()
const { user } = storeToRefs(globalStore)
const { getUser, initNotificaiton, getNotifications, getBlocks } = globalStore
const preferenceStore = usePreferenceStore()
const { getPreference } = preferenceStore
const { setting } = storeToRefs(preferenceStore)

const { locale, locales, setLocale } = useNuxtApp().$i18n

const { sleep, formatTime } = useTool()
const { messageInfo } = useSwal()

const isOnline = useOnline()
const token = useCookie('token')

useSeoMeta({
  title: metaData.name,
  ogTitle: metaData.name,
  description: metaData.description,
  ogDescription: metaData.description,
  ogImage: 'https://jasonxddd.me:9000/bottleneko/bottleneko-v2.png',
  twitterCard: 'summary_large_image',
  ogLocale: locale.value,
  ogLocaleAlternate: locales.value.map(l => l.code)
})

useHead({
  script: [
    {
      src: 'https://www.googletagmanager.com/gtag/js?id=G-085WF7XP08',
      async: true
    },
    {
      children: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-085WF7XP08');
      `
    }
  ]
})

const checkUserHeroInfo = async () => {
  // if (user.value.role === 'member' && !user.value.heroExpired) {
  //   await messageInfo(`
  //     <div class="h-full w-full flex flex-col md:flex-row items-center gap-2">
  //       <span>您的英雄會員將會在 2024-04-22 會自動更新日期，詳情請見 "工作坊/英雄榜"。</span>
  //       <button class="w-full md:w-fit ml-auto rounded-xl bg-white text-black px-2 py-1">我知道了</button>
  //     </div>
  //   `)
  // }

  // check hero expired < 3 days
  if (user.value.role === 'member' && user.value.heroExpired) {
    let needNotify = new Date(user.value.heroExpired).getTime() - new Date().getTime() < 3 * 24 * 60 * 60 * 1000
    if (needNotify) {
      await messageInfo(`
        <div class="h-full w-full flex flex-col md:flex-row items-center gap-2">
          <span>您的英雄會員即將於 ${formatTime(user.value.heroExpired)} 到期，可至英雄榜進行訂閱，如您已經訂閱 Patreon 則不用理會。</span>
          <button class="w-full md:w-fit ml-auto rounded-xl bg-white text-black px-2 py-1">我知道了</button>
        </div>
      `)
    }
  }
}

onMounted(async () => {
  // local deck hotfix transform to localStorage.localDecks
  const decks = JSON.parse(localStorage.getItem('deck') || '[]')
  if (Array.isArray(decks) && decks.length > 0) {
    localStorage.setItem('localDecks', JSON.stringify(decks))
  }

  // init
  await sleep(100)

  if (token.value) {
    await Promise.all([getUser(), getMyDecks(), getPreference(), getBlocks()])
  }

  // fix locale name
  const nowLocale = locales.value.find(l => l.code === locale.value)
  if (nowLocale.name !== setting.value.systemLanguage) {
    setting.value.systemLanguage = nowLocale.name
  }

  await getSeries()

  await initNotificaiton()
  await getNotifications()

  // check user hero info
  await checkUserHeroInfo()
})
</script>
