export const CardTranslate = {
  '：このカードがトリガーした時、あなたは自分の控え室のトリガーアイコンにがあるキャラを1枚選び、手札に戻すかストック置場に置いてよい':
    '：當你判定出此卡時，你可以選擇自己休息室的判定標誌有⚜的1張角色，回到手牌或放到能量區',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分のクロックの上から1枚を、控え室に置いてよい。':
    '【自】此卡從手牌被放置到舞台時，你可以將自己的傷害區上方1張，放到休息室。',
  '【自】 あなたのクライマックスがクライマックス置場に置かれた時、あなたは自分のキャラを1枚選び、そのターン中、パワーを＋1000。':
    '【自】當你的名場面被放置到名場面區時，你選擇自己的1張角色，在那個回合中，攻擊力+1000。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは相手のキャラを1枚選び、次の相手のターンの終わりまで、次の能力を与える。『【永】 このカードは他の枠に動かせない。』':
    '【自】此卡從手牌放置到舞台時，你選擇對手的1張角色，到下次的對手回合結束前，給予以下能力。『【永】此卡不能移動到其他格子。』',
  '【永】 このカードは、色条件を満たさずに手札からプレイできる。': '【永】此卡可以無需滿足顏色條件從手牌使用。',
  '【永】 応援 このカードの前のあなたのレベル3以上のキャラすべてに、パワーを＋2000。': '【永】應援 此卡前面你的等級3以上的角色，攻擊力+2000。',
  '【自】［(2) このカードを手札に戻す］ 相手のアタックフェイズの始めに、あなたはコストを払ってよい。そうしたら、あなたは他の自分のキャラを1枚選び、手札に戻す。':
    '【自】［(2) 將此卡返回手牌］在對手的攻擊階段開始時，你可以支付代價。然後，你選擇其他的自己的角色1張，返回手牌。',
  '【自】 アンコール ［手札のキャラを1枚控え室に置く］（このカードが舞台から控え室に置かれた時、あなたはコストを払ってよい。そうしたら、このカードがいた枠に【レスト】して置く）':
    '【自】安可［手牌的角色1張放到休息室］(此卡從舞台被放到休息室時，你可以支付代價。然後，你將此卡【橫置】放回原位)',
  '【永】 相手のレベル3以上のキャラがいるなら、あなたの手札のこのカードのレベルを－1。':
    '【永】有對手的等級3以上的角色存在的話，你的手牌的此卡等級-1。',
  '【自】 このカードが手札から舞台に置かれた時、相手は0か1か2か3を宣言する。あなたは自分の山札の上から1枚を、控え室に置き、そのカードが相手の宣言したレベルのカードなら、あなたはＸ枚まで引く。Ｘは相手の宣言した数＋1に等しい。（クライマックスのレベルは0として扱う）':
    '【自】此卡從手牌被放置到舞台時，對手宣言0或1或2或3。你將自己的牌組上方1張，放到休息室，那張卡片是對手宣言的等級的卡片的話，你抽最多X張。X等於對手宣言的數字+1。(名場面視為等級0)',
  '【自】［(1)］ このカードがアタックした時、あなたはコストを払ってよい。そうしたら、そのアタック中、あなたはトリガーステップにトリガーチェックを2回行う。':
    '【自】［(1)］此卡攻擊時，你可以支付代價。然後，在那次攻擊中，你在判定階段執行2次判定。',
  '【自】 相手のドローフェイズの始めに、あなたは自分の山札の上から1枚を公開する。そのカードのレベルが1以上なら、あなたはこのカードを手札に戻してよい。（クライマックスのレベルは0として扱う。公開したカードは元に戻す）':
    '【自】在對手的抽牌階段開始時，你將自己的牌組上方1張公開。那張卡片的等級是1以上的話，你可以將此卡返回手牌。(名場面視為等級0。公開過的卡片放回原位)',
  '【永】 あなたのストックが2枚以下なら、このカードのパワーを＋1500。': '【永】你的能量在2張以下的話，此卡的攻擊力+1500。',
  '【自】［(1) 手札を1枚控え室に置く］ このカードが手札から舞台に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分の山札を上から3枚まで見て、カードを1枚まで選び、手札に加え、残りのカードを控え室に置く。':
    '【自】［(1) 手牌1張放到休息室］此卡從手牌被放置到舞台時，你可以支付代價。然後，你從自己的牌組上方看最多3張，選擇卡片最多1張，加到手牌，剩下的卡片放到休息室。',
  '【自】 このカードが【リバース】した時、このカードのバトル相手のレベルが相手のレベルより高いなら、あなたはそのキャラをストック置場に置いてよい。そうしたら、あなたは相手のストックの下から1枚を、控え室に置く。':
    '【自】此卡【倒置】時，此卡的戰鬥對手的等級比對手等級還要高的話，你可以將那張角色放到能量區。然後，你將對手的能量區下方1張，放到休息室。',
  '【永】 あなたの控え室のクライマックスが2枚以下なら、あなたの手札のこのカードのレベルを－1。':
    '【永】你的休息室的名場面在2張以下的話，你的手牌的此卡等級-1。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分の山札を上からＸ枚まで見て、カードを1枚まで選び、手札に加え、残りのカードを控え室に置く。Ｘは相手のキャラの枚数に等しい。':
    '【自】此卡從手牌被放置到舞台時，你從自己的牌組上方看最多X張，選擇卡片最多1張，加到手牌，剩下的卡片放到休息室。X等於對手的角色張數。',
  '【自】［(1) 手札のクライマックスを1枚控え室に置く］このカードが手札から舞台に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分の控え室のクライマックスを1枚選び、手札に戻す。':
    '【自】［(1) 手牌的名場面1張放到休息室］此卡從手牌被放置到舞台時，你可以支付代價。然後，你選擇自己休息室的名場面1張，回到手牌。',
  '【自】 このカードが手札から舞台に置かれた時、そのターン中、このカードのパワーを＋1500。':
    '【自】此卡從手牌被放置到舞台時，在那個回合中，此卡的攻擊力+1500。',
  '【自】［手札を2枚控え室に置く］ あなたがこのカードの『助太刀』を使った時、あなたはコストを払ってよい。そうしたら、あなたは相手の、レベルが相手のレベルより高いキャラを1枚選び、控え室に置く。':
    '【自】［手牌2張放到休息室］當你使用此卡的『助太刀』時，你可以支付代價。然後，你選擇對手的，等級比對手等級還要高的角色1張，放到休息室。',
  '【起】【カウンター】 助太刀2500 レベル2 ［(1) 手札のこのカードを控え室に置く］ （あなたは自分のフロントアタックされているキャラを1枚選び、そのターン中、パワーを＋2500）':
    '【起】【反擊】助太刀2500 等級2［(1) 手牌的此卡放到休息室］(你選擇自己的正在被正打的角色1張，在那個回合中，攻擊力+2500)',
  '【自】［手札を1枚控え室に置く］ このカードが手札から舞台に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分の控え室のレベル0以下のキャラを1枚選び、舞台の好きな枠に置く。':
    '【自】［手牌1張放到休息室］此卡從手牌被放置到舞台時，你可以支付代價。然後，你選擇自己休息室的等級0以下的角色1張，放到舞台上任意的格子。',
  '【自】 バトル中のこのカードが【リバース】した時、このカードを山札の下に置く。': '【自】戰鬥中的此卡【倒置】時，將此卡放到牌組下方。',
  '【永】 他のあなたのキャラすべてに、次の能力を与える。『【永】 このカードはサイドアタックできない。』':
    '【永】給予其他的你的角色全部，以下能力。『【永】此卡不能側打。』',
  '【起】［(1) このカードを控え室に置く］ あなたは自分の手札の自分のレベル以下のレベルのキャラを1枚まで選び、このカードがいた枠に置く。':
    '【起】［(1) 將此卡放到休息室］你選擇自己的手牌的等級在自己的等級以下的角色最多1張，放到此卡原本的格子。',
  '【自】 このカードが【リバース】した時、このカードのバトル相手のレベルが相手のレベルより高いなら、あなたは相手のクロックの上から1枚を、控え室に置いてよい。そうしたら、あなたはそのキャラをクロック置場に置く。':
    '【自】此卡【倒置】時，此卡的戰鬥對手的等級比對手等級還要高的話，你可以將對手的傷害區上方1張，放到休息室。然後，你將那張角色放到傷害區。',
  '【自】 このカードが手札から舞台に置かれた時、次の相手のターンの終わりまで、このカードのパワーを＋4500し、このカードは次の能力を得る。『【永】 このカードのバトル中、すべてのプレイヤーは『助太刀』を手札からプレイできない。』':
    '【自】此卡從手牌被放置到舞台時，到下次的對手回合結束前，此卡的攻擊力+4500，此卡獲得以下能力。『【永】在此卡的戰鬥中，全部的玩家不能從手牌使用『助太刀』。』',
  '【自】 このカードのバトル相手が【リバース】した時、あなたはそのキャラを思い出にしてよい。':
    '【自】此卡的戰鬥對手【倒置】時，你可以將那張角色放到回憶區。',
  '【永】 このカードの正面のキャラのコストが0以下なら、このカードは【リバース】しない。': '【永】此卡正面的角色的費用是0以下的話，此卡不會【倒置】。',
  '【起】 集中 ［(1) このカードを【レスト】する］ あなたは自分の山札の上から4枚をめくり、控え室に置く。それらのカードのクライマックス1枚につき、あなたは自分の控え室のキャラを1枚まで選び、手札に戻す。':
    '【起】集中［(1) 將此卡【橫置】］你從自己的牌組上方翻4張，放到休息室。那些卡片中每有1張名場面，你選擇自己休息室的角色最多1張，回到手牌。',
  '【自】［手札を1枚控え室に置く］ このカードが手札から舞台に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分のクロックの上から1枚を、ストック置場に置く。':
    '【自】［手牌1張放到休息室］此卡從手牌被放置到舞台時，你可以支付代價。然後，你將自己的傷害區上方1張，放到能量區。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分の山札を上から3枚まで見て、カードを1枚まで選び、手札に加え、残りのカードを控え室に置く。':
    '【自】此卡從手牌被放置到舞台時，你從自己的牌組上方看最多3張，選擇卡片最多1張，加到手牌，剩下的卡片放到休息室。',
  '【永】 応援 このカードの前のあなたのキャラすべてに、パワーを＋Ｘ。Ｘはそのキャラのレベル×500に等しい。':
    '【永】應援 此卡前面的你的角色全部，攻擊力+X。X等於那張角色的等級×500。',
  '【永】 応援 このカードの前のあなたのキャラすべてに、パワーを＋500。': '【永】應援 此卡前面的你的角色全部，攻擊力+500。',
  '【自】 このカードが【リバース】した時、このカードのバトル相手のレベルが0以下なら、あなたはそのキャラを【リバース】してよい。':
    '【自】此卡【倒置】時，此卡的戰鬥對手是等級0以下的話，你可以將那張角色【倒置】。',
  '【自】［(1) 手札を1枚控え室に置く］ このカードが手札から舞台に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分の控え室のキャラを1枚選び、手札に戻す。':
    '【自】［(1) 手牌1張放到休息室］此卡從手牌被放置到舞台時，你可以支付代價。然後，你選擇自己休息室的角色1張，回到手牌。',
  '【自】【クロック】 アラーム このカードがクロックの1番上にあるなら、あなたのクライマックスフェイズの始めに、あなたは1枚引いてよい。そうしたら、あなたは自分の手札を1枚選び、控え室に置く。':
    '【自】計時 此卡在你的傷害區最上方的話，在你的名場面階段開始時，你可以抽1張牌。然後，你選擇自己的手牌1張，放到休息室。',
  '【自】［このカードを手札に戻す］ あなたのクライマックスがクライマックス置場に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分のキャラを1枚選び、次の相手のターンの終わりまで、パワーを＋1000。':
    '【自】［將此卡返回手牌］當你的名場面被放置到名場面區時，你可以支付代價。然後，你選擇自己的角色1張，到下次的對手回合結束前，攻擊力+1000。',
  '【永】 あなたの手札が5枚以上なら、このカードのパワーを＋2000。': '【永】你的手牌在5張以上的話，此卡的攻擊力+2000。',
  '【自】［(1)］ アンコールステップの始めに、他のあなたの前列の【レスト】しているキャラがいないなら、あなたはコストを払ってよい。そうしたら、このカードを【レスト】する。':
    '【自】［(1)］在安可階段開始時，沒有其他的你的前列的【橫置】的角色存在的話，你可以支付代價。然後，將此卡【橫置】。',
  '【自】 バトル中のこのカードが【リバース】した時、このカードのバトル相手のレベルが0以下なら、あなたはそのキャラを山札の下に置いてよい。':
    '【自】此卡【倒置】時，此卡的戰鬥對手是等級0以下的話，你可以將那張角色放到牌組下方。',
  '【永】 このカードのバトル中、相手はイベントと『助太刀』を手札からプレイできない。': '【永】在此卡的戰鬥中，對手不能從手牌使用事件和『助太刀』。',
  '【永】 このカードの正面のキャラのレベルがこのカードのレベルより高いなら、このカードはフロントアタックできない。':
    '【永】此卡正面的角色的等級比此卡的等級還要高的話，此卡不能正打。',
  '【自】このカードが【リバース】した時、このカードのバトル相手のコストが0以下なら、あなたは相手のクロックの上から1枚を、控え室に置いてよい。そうしたら、あなたはそのキャラをクロック置場に置く。':
    '【自】此卡【倒置】時，此卡的戰鬥對手是費用0以下的話，你可以將對手的傷害區上方1張，放到休息室。然後，你將那張角色放到傷害區。',
  'あなたがこのカードの『助太刀』を使った時、あなたはコストを払ってよい。そうしたら、あなたは相手のキャラを1枚選び、【レスト】する。':
    '當你使用此卡的『助太刀』時，你可以支付代價。然後，你選擇對手的角色1張，【橫置】。',
  '【自】バトル中のこのカードが【リバース】した時、そのターン中、あなたは『【自】アンコール』を使えない。（ルールによる『【自】アンコール ［(3)］』も使えない）':
    '【自】戰鬥中的此卡【倒置】時，在那個回合中，你不能使用『【自】安可』。(根據規則的『【自】安可［(3)］』也不能使用)',
  '【自】［(1) 手札を1枚控え室に置く］ このカードがダイレクトアタックした時、あなたはコストを払ってよい。そうしたら、相手に1ダメージを与える。（ダメージキャンセルは発生する）':
    '【自】［(1) 手牌1張放到休息室］此卡直接攻擊時，你可以支付代價。然後，給對手1點傷害。(會發生傷害取消)',
  '【自】 このカードのバトル相手が【リバース】した時、あなたのクライマックス置場にクライマックスがあるなら、あなたは自分の山札の上から1枚を、ストック置場に置いてよい。':
    '【自】此卡的戰鬥對手【倒置】時，你的名場面區有名場面的話，你可以將自己的牌組上方1張，放到能量區。',
  '【起】 集中 ［(1) このカードを【レスト】する］ あなたは自分の山札の上から4枚をめくり、控え室に置く。それらのカードのクライマックス1枚につき、次の行動を行う。『あなたは自分の山札を上から3枚まで見て、カードを1枚まで選び、手札に加え、残りのカードを控え室に置く。』':
    '【起】集中［(1) 將此卡【橫置】］你從自己的牌組上方翻4張，放到休息室。那些卡片中每有1張名場面，你執行以下的動作。『你從自己的牌組上方看最多3張，選擇卡片最多1張，加到手牌，剩下的卡片放到休息室。』',
  '【自】［手札を1枚控え室に置く］ このカードが舞台から控え室に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分の山札を上から4枚まで見て、レベル1以上のカードを1枚まで選んで相手に見せ、手札に加え、残りのカードを控え室に置く。（クライマックスのレベルは0として扱う）':
    '【自】［手牌1張放到休息室］此卡從舞台被放到休息室時，你可以支付代價。然後，你從自己的牌組上方看最多4張，選擇等級1以上的卡片最多1張給對手確認，加到手牌，剩下的卡片放到休息室。(名場面視為等級0)',
  '【自】［手札のクライマックスを1枚控え室に置く］ あなたのキャラのトリガーチェックでクライマックスがでた時、あなたはコストを払ってよい。そうしたら、あなたは自分の山札を上から2枚まで見て、カードを1枚まで選び、手札に加え、残りのカードを控え室に置く。':
    '【自】［手牌的名場面1張放到休息室］當你的角色在判定階段判出名場面時，你可以支付代價。然後，你從自己的牌組上方看最多2張，選擇卡片最多1張，加到手牌，剩下的卡片放到休息室。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分の山札の上から3枚を、控え室に置いてよい。':
    '【自】此卡從手牌被放置到舞台時，你可以將自己的牌組上方3張，放到休息室。',
  '【自】 このカードが手札から舞台に置かれた時、すべてのプレイヤーは次の行動を行う。『あなたの思い出が5枚以上なら、あなたは自分の思い出置場のカードを4枚選び、それらのカード以外の自分の思い出置場のカードすべてを、控え室に置く。』':
    '【自】此卡從手牌被放置到舞台時，全部的玩家執行以下的動作。『你的回憶區有5張以上的話，你選擇自己回憶區的卡片4張，除那些卡片以外的自己回憶區的卡片全部，放到休息室。』',
  '【自】 このカードが手札から舞台に置かれた時、あなたは相手のキャラを1枚まで選び、思い出にし、相手は自分の思い出置場のそのキャラを、舞台の好きな枠に置く。':
    '【自】此卡從手牌被放置到舞台時，你選擇對手的角色最多1張，放到回憶區，對手將自己回憶區的那張角色，放到舞台上的任意格子。',
  '【自】［あなたの控え室のキャラを2枚山札に戻し、その山札をシャッフルする］ このカードが手札から舞台に置かれた時、あなたはコストを払ってよい。そうしたら、そのターン中、このカードはサイドアタックしてもソウルが減少しない。':
    '【自】［你的休息室的角色2張回到牌組，將那個牌組洗牌］此卡從手牌被放置到舞台時，你可以支付代價。然後，在那個回合中，此卡側打也不會減少魂傷。',
  '【永】 あなたはイベントと『助太刀』を手札からプレイできない。': '【永】你不能從手牌使用事件或『助太刀』。',
  '【自】 このカードが【リバース】した時、このカードのバトル相手のレベルが相手のレベルより高いなら、あなたはそのキャラを思い出にしてよい。':
    '【自】此卡【倒置】時，此卡的戰鬥對手的等級比對手等級還要高的話，你可以將那張角色放到回憶區。',
  '【自】 このカードがアタックした時、このカードの正面のキャラのレベルが3以上なら、そのターン中、このカードのパワーを＋6000。':
    '【自】此卡攻擊時，此卡正面的角色的等級是3以上的話，在那個回合中，此卡的攻擊力+6000。',
  '【自】 相手のアタックフェイズの始めに、あなたはこのカードを、前列のキャラのいない枠で、正面に相手のキャラがいる枠に動かしてよい。':
    '【自】在對手的攻擊階段開始時，你可以將此卡移動到前列的沒有角色，正面有對手的角色的格子。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分の山札を上から2枚まで見て、山札の上に好きな順番で置く。':
    '【自】此卡從手牌被放置到舞台時，你看自己的牌組上方最多2張，以任意順序放到牌組上方。',
  '【自】［手札を1枚控え室に置く］ あなたのキャラのトリガーチェックでトリガーアイコンがのクライマックスがでた時、あなたのクライマックス置場にクライマックスがあるなら、あなたはコストを払ってよい。そうしたら、あなたは自分の控え室のキャラを1枚選び、手札に戻す。':
    '【自】［手牌1張放到休息室］當你的角色在判定階段判出判定標誌是城門的名場面時，你的名場面區有名場面的話，你可以支付代價。然後，你選擇自己休息室的角色1張，回到手牌。',
  '【自】 あなたがこのカードの『助太刀』を使った時、あなたは自分の山札の上から3枚を、控え室に置く。':
    '【自】當你使用此卡的『助太刀』時，你將自己的牌組上方3張，放到休息室。',
  '【永】 このカードと同じカード名のカードは、デッキに%s枚まで入れることができる。': '【永】與此卡同卡名的卡片，可以在牌組中放入最多%s張。',
  '【永】 このカードと同じカード名のカードは、デッキに好きな枚数入れることができる。': '【永】與此卡同卡名的卡片，可以在牌組中放入任意張數。',
  '【起】［(2) このカードを【レスト】する］ あなたは自分のクロックの上から1枚を、控え室に置く。':
    '【起】［(2) 將此卡【橫置】］你將自己的傷害區上方1張，放到休息室。',
  '【起】［このカードを【レスト】する］ あなたはこのカードの下のマーカーを1枚ストック置場に置く。':
    '【起】［將此卡【橫置】］你將此卡下方的標記1張放到能量區。',
  '【自】 このカードがアタックした時、他のあなたの後列のキャラがいないなら、あなたは自分の山札の上から1枚を、控え室に置いてよい。そのカードがレベル0以下のキャラなら、あなたはそのキャラを後列の好きな枠に置く。':
    '【自】此卡攻擊時，沒有其他的你的後列的角色存在的話，你可以將自己的牌組上方1張，放到休息室。那張卡片是等級0以下的角色的話，將那張角色放到後列的任意格子。',
  '【永】 相手のキャラすべてに、『【自】 アンコール ［(2)］』を与える。': '【永】給予對手的角色全部『【自】安可［(2)］』。',
  '【自】 この能力は1ターンにつき1回まで発動する。あなたが【起】を使った時、そのターン中、このカードは次の能力を得る。『【自】 このカードのバトル相手が【リバース】した時、あなたは自分の山札の上から1枚を、ストック置場に置いてよい。』':
    '【自】此能力每1回合最多發動1次。當你使用【起】時，在那個回合中，此卡獲得以下能力。『【自】此卡的戰鬥對手【倒置】時，你可以將自己的牌組上方1張，放到能量區。』',
  '【自】［手札を1枚控え室に置く］ 相手のアタックフェイズの始めに、あなたはコストを払ってよい。そうしたら、あなたはこのカードを後列のキャラのいない枠に動かす。':
    '【自】［手牌1張放到休息室］在對手的攻擊階段開始時，你可以支付代價。然後，你將此卡移動到後列的沒有角色的格子。',
  '【自】 このカードのバトル中、あなたの受けたダメージがキャンセルされた時、あなたはこのカードをストック置場に置いてよい。':
    '【自】在此卡的戰鬥中，你所受到的傷害被取消時，你可以將此卡放到能量區。',
  '【自】 このカードの与えたダメージがキャンセルされた時、あなたはこのカードを手札に戻してよい。':
    '【自】此卡給予的傷害被取消時，你可以將此卡返回手牌。',
  '【自】 バトル中のこのカードが【リバース】した時、このカードを思い出にする。': '【自】戰鬥中的此卡【倒置】時，將此卡放到回憶區。',
  '相手は自分のストックすべてを、控え室に置き、自分の山札の上から同じ枚数をストック置場に置く':
    '對手將自己的能量全部，放到休息室，從自己的牌組上方將相同張數放到能量區。',
  '【自】 相手のアタックフェイズの始めに、このカードの正面に相手のキャラがいるなら、あなたはこのカードを前列のキャラのいない枠に動かしてよい。':
    '【自】在對手的攻擊階段開始時，此卡的正面有對手的角色存在的話，你可以將此卡移動到前列的沒有角色的格子。',
  '【自】 あなたがこのカードの『助太刀』を使った時、あなたは自分のバトル中のキャラを1枚選び、そのターン中、次の能力を与える。『【自】 このカードのバトル相手が【リバース】した時、あなたはそのキャラを思い出にする。』':
    '【自】當你使用此卡的『助太刀』時，你選擇自己的戰鬥中的角色1張，在那個回合中，給予以下能力。『【自】此卡的戰鬥對手【倒置】時，你將那張角色放到回憶區。』',
  '【自】［(5) 手札を2枚控え室に置く］ この能力は1ターンにつき1回まで発動する。このカードのアタックの終わりに、あなたはコストを払ってよい。そうしたら、このカードを【スタンド】する。':
    '【自】［(5) 手牌2張放到休息室］此能力每1回合最多發動1次。此卡的攻擊結束時，你可以支付代價。然後，將此卡【正置】。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分の手札を1枚選び、ストック置場に置いてよい。':
    '【自】此卡從手牌被放置到舞台時，你可以選擇自己的手牌1張，放到能量區。',
  '【自】 このカードの与えたダメージがキャンセルされた時、あなたはこのカードをストック置場に置いてよい。':
    '【自】此卡給予的傷害被取消時，你可以將此卡放到能量區。',
  '【自】 このカードのバトル相手が【リバース】した時、あなたは相手のクロックの上から1枚を、控え室に置いてよい。そうしたら、あなたはそのキャラをクロック置場に置く。':
    '【自】此卡的戰鬥對手【倒置】時，你可以將對手的傷害區上方1張，放到休息室。然後，你將那張角色放到傷害區。',
  '【自】［(1)］ 相手のアタックフェイズの始めに、あなたはコストを払ってよい。そうしたら、あなたはこのカードを後列のキャラのいない枠に動かす。':
    '【自】［(1)］在對手的攻擊階段開始時，你可以支付代價。然後，你將此卡移動到後列的沒有角色的格子。',
  '【自】 相手のアタックフェイズの始めに、あなたは相手の前列のキャラを1枚選び、前列のキャラのいない他の枠に動かしてよい。':
    '【自】在對手的攻擊階段開始時，你可以選擇對手的前列的角色1張，移動到前列的沒有角色的其他格子。',
  '【自】 このカードがアタックした時、あなたは自分の山札を上から2枚まで見て、カードを1枚選び、山札の上に置き、残りのカードを控え室に置く。':
    '【自】此卡攻擊時，你看自己的牌組上方最多2張，選擇卡片1張，放到牌組上方，剩下的卡片放到休息室。',
  '【自】［(2)］ このカードが手札から舞台に置かれた時、あなたはコストを払ってよい。そうしたら、あなたは自分の控え室のカードすべてを、山札に戻し、その山札をシャッフルする。':
    '【自】［(2)］此卡從手牌被放置到舞台時，你可以支付代價。然後，你將自己休息室的卡片全部，返回牌組，將那個牌組洗牌。',
  '【自】 相手のターン中、あなたの受けたダメージがキャンセルされなかった時、前列にこのカードがいるなら、あなたは自分の山札を上から1枚見て、山札の上か控え室に置く。':
    '【自】在對手的回合中，你所受到的傷害沒有被取消時，前列有此卡存在的話，你看自己的牌組上方1張，放到牌組上方或休息室。',
  'あなたがこのカードの『助太刀』を使った時、あなたはコストを払ってよい。そうしたら、あなたは相手の、レベルが相手のレベルより高いキャラを1枚選び、山札の下に置く。':
    '當你使用此卡的『助太刀』時，你可以支付代價。然後，你選擇對手的，等級比對手等級還要高的角色1張，放到牌組下方。',
  '【自】 このカードがアタックした時、このカードの正面のキャラのレベルが2なら、そのターン中、このカードのパワーを＋6000。':
    '【自】此卡攻擊時，此卡正面的角色的等級是2的話，在那個回合中，此卡的攻擊力+6000。',
  '【自】 バトル中のこのカードが【リバース】した時、あなたの思い出が2枚以下なら、あなたはこのカードを思い出にしてよい。':
    '【自】戰鬥中的此卡【倒置】時，你的回憶區在2張以下的話，你可以將此卡放到回憶區。',
  '【永】 他のあなたのキャラが1枚以下なら、このカードのパワーを＋1500。': '【永】其他的你的角色在1張以下的話，此卡的攻擊力+1500。',
  '【永】 他のあなたのキャラが1枚以下なら、他のあなたのキャラすべてに、パワーを＋1500。':
    '【永】其他的你的角色在1張以下的話，其他的你的角色全部，攻擊力+1500。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分の山札を上から1枚見て、山札の上か控え室に置く。':
    '【自】此卡從手牌被放置到舞台時，你看自己的牌組上方1張，放到牌組上方或休息室。',
  '【自】 このカードが手札から舞台に置かれた時、あなたは自分の山札の上から2枚を、控え室に置く。それらのカードにレベル0以下のキャラがあるなら、そのターン中、このカードはサイドアタックしてもソウルが減少しない。':
    '【自】此卡從手牌被放置到舞台時，你將自己的牌組上方2張，放到休息室。那些卡片中有等級0以下的角色的話，在那個回合中，此卡側打也不會減少魂傷。',
  '【自】 相手のアタックフェイズの始めに、あなたはこのカードを前列のキャラのいない枠に動かしてよい。':
    '【自】在對手的攻擊階段開始時，你可以將此卡移動到前列的沒有角色的格子。',
  '【永】 前列の中央の枠にこのカードがいるなら、このカードのソウルを＋1。': '【永】前列中央的格子有此卡存在的話，此卡的魂傷+1。',
  '【自】 この能力は1ターンにつき1回まで発動する。バトル中のこのカードが【リバース】した時、あなたは自分の山札の上から1枚を公開する。そのカードのレベルが2以上なら、あなたはこのカードを【レスト】してよい。（クライマックスのレベルは0として扱う。公開したカードは元に戻す）':
    '【自】此能力每1回合最多發動1次。戰鬥中的此卡【倒置】時，你將自己的牌組上方1張公開。那張卡片的等級是2以上的話，你可以將此卡【橫置】。(名場面視為等級0。公開過的卡片放回原位)',
  '【自】 相手のアタックフェイズの始めに、あなたは自分の山札の上から1枚を、控え室に置いてよい。そのカードが《%s》のキャラなら、あなたはこのカードを前列のキャラのいない枠に動かしてよい。':
    '【自】在對手的攻擊階段開始時，你可以將自己的牌組上方1張，放到休息室。那張卡片是《%s》的角色的話，你可以將此卡移動到前列的沒有角色的格子。',
}

export const CardZh = {
  '相手がアタックする時、【リバース】していないこのカードが前列の中央の枠にいるなら、かわりに相手はこのカードにフロントアタックする':
    '對手攻擊時，未【倒置】的此卡在前列中央的格子的話，改為對手對此卡進行正打',
  クライマックスのレベルは0として扱う: '名場面視為等級0',
  公開したカードは元に戻す: '公開的卡片會回復原狀',
  そうでないなら元に戻す: '如果不是的話回復原狀',
  'ルールによる『【自】 アンコール ［(3)］』も使えない': '規則上的『【自】再演［(3)］』 也不能使用',
  ダメージキャンセルは発生する: '傷害可以被取消',
  色条件を満たさずに手札からプレイできる: '不需滿足顏色條件從手牌打出',
  'あなたはイベントと『助太刀』を手札からプレイできない': '你不能從手牌使用事件或『助太刀』',
  このカードは他の枠に動かせない: '此卡不能移動到其他格子',
  このカードは相手の効果に選ばれない: '這張卡不會被對手的效果所選擇',
  '『【自】 アンコール』を使えない': '不能使用『【自】再演』',
  この能力は1ターンにつき1回まで発動する: '這個能力一個回合最多只能觸發一次',
  'このカードの正面のキャラがいないか【リバース】しているなら': '這張卡正面沒有角色或是正面的角色【倒置】的話',
  'あなたがこのカードの『助太刀』を使った時': '你將這張卡作為助太刀使用時',
  手札から舞台に置かれた時: '手牌放置到舞台時',
  舞台から控え室に置かれた時: '舞台放置到休息室時',
  受けたダメージがキャンセルされなかった時: '受到的傷害沒有取消時',
  受けたダメージがキャンセルされた時: '受到的傷害取消時',
  与えたダメージがキャンセルされなかった時: '給予的傷害沒有取消時',
  与えたダメージがキャンセルされた時: '給予的傷害被取消時',
  次の相手のターンの終わりまで: '到下個對手的回合結束為止',
  ターンの終わりに: '回合結束時',
  前列にこのカードがいて: '如果這張卡在前列',
  前列にこのカードがいるなら: '如果這張卡在前列',
  トリガーチェックでトリガーアイコン: '判定動作翻到判定標誌',
  ダメージ処理: '傷害處理',
  このターン: '這個回合',
  そのターン: '那個回合',
  終わり: '結束',
  始めに: '開始時',
  プレイ: '打出',
  レベルが相手のレベルより高いキャラ: '等級比對手等級高的角色',
  バトルしているキャラ: '戰鬥中的角色',
  相手にＸダメージを与える: '給予對手X點傷害',
  つの効果のうちあなたが選んだ1つを行う: '個效果你選擇一個執行',
  選び: '選擇',
  次の能力を得る: '獲得以下能力',
  次の能力を与える: '賦予以下能力',
  あなたはコストを払ってよい: '你可以支付代價',
  レベルアップ: '升等',
  リフレッシュポイント: '洗傷',
  リフレッシュ: '牌組重置',
  リシャッフル: '重洗牌',
  シャッフル: '洗牌',
  手札に加え: '加入手牌',
  山札に戻: '回到牌組',
  手札に戻: '回到手牌',
  入れ替え: '交換',
  スタンドフェイズ: '重置階段',
  ドローフェイズ: '抽牌階段',
  クロックフェイズ: '時計階段',
  メインフェイズ: '主要階段',
  クライマックスフェイズ: '名場面階段',
  アタックフェイズ: '攻擊階段',
  エンドフェイズ: '結束階段',
  アンコールステップ: '再演步驟',
  キャラ: '角色',
  イベント: '事件',
  カード: '卡片',
  シフト: '轉移',
  アラーム: '警報',
  アンコール: '再演',
  チェンジ: '變身',
  CXコンボ: '名場面連動',
  カウンターアイコン: '反擊標誌',
  カウンター: '反擊',
  クライマックス置場: '名場面區域',
  クライマックス: '名場',
  クロック: '傷害區',
  ストック置場: '能量區域',
  ストック: '能量',
  レベル置場: '等級區',
  レベル: '等級',
  控え室: '休息室',
  思い出置場: '回憶區',
  思い出: '回憶',
  山札: '牌組',
  手札: '手牌',
  マーカー: '指示牌',
  枠: '位置',
  テキスト: '效果欄',
  リバース: '倒置',
  レスト: '橫置',
  バトル: '戰鬥',
  スタンド: '正置',
  サイドアタック: '側面攻擊',
  ダイレクトアタック: '直接攻擊',
  フロントアタック: '正面攻擊',
  アタックした時: '攻擊時',
  アタック: '攻擊',
  トリガーチェック: '判定動作',
  トリガーステップ: '判定步驟',
  トリガーアイコン: '判定標誌',
  トリガー: '判定',
  ソウル: '魂傷',
  パワー: '攻擊力',
  ターンプレイヤー: '回合玩家',
  プレイヤー: '玩家',
  オーナー: '持有者',
  マスター: '持有者',
  あなたは自分: '你自己',
  自分の: '自己的',
  あなた: '你',
  相手の: '對手的',
  相手: '對手',
  'そうしたら、': '如果這麼做的話，',
  'があり、': '有的話，',
  'できない，': '無法做，',
  'ないなら，': '如果沒有的話，',
  'いるなら，': '如果存在的話，',
  があるなら: '有的話，',
  なら: '的話，',
  この: '這個',
  その: '那個',
  ターン: '回合',
  できない: '無法',
  すべて: '全部',
  コスト: '費用',
  払い: '支付',
  払って: '支付',
  ダメージ: '傷害',
  置く: '放置',
  まで: '最多',
  をめくり: '翻開',
  引く: '抽牌',
  引いてよい: '抽牌',
  引き: '抽牌',
  与え: '給予',
  残り: '剩餘',
  好きな: '任意',
  順番: '順序',
  につき: '每有',
  他の: '其他',
  しない: '不會',
  と: '與',
  yellow: '黃',
  red: '紅',
  blue: '藍',
  green: '綠',
  purple: '紫',
  none: '無',
  soul: '魂',
  soul2: '雙魂',
  stock: '金袋',
  salvage: '木門',
  bounce: '風',
  draw: '書',
  treasure: '金磚',
  shot: '火',
  gate: '城門',
  standby: '開機',
  choice: '箭頭',
  黄: '黃',
  赤: '紅',
  青: '藍',
  緑: '綠',
  紫: '紫',
}
