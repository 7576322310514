const MAX_RELOAD = 3
import { defaultFilter } from '@/constants/qa'
import { api } from '@/constants/api'

export const useGlobalStore = defineStore('global', () => {
  const { doFetch } = useFetchApi()
  const { requestPermission, initMessaging } = useFirebase()
  const { messageToast, messageSuccess } = useSwal()
  const { t } = useNuxtApp().$i18n

  // User
  const user = ref(false)

  // member
  const member = ref()

  // block
  const blocks = ref([])

  // Notification
  const fcmToken = ref()
  const notifications = ref([])
  const noReadCount = computed(() => (notifications.value || []).filter((item) => !item.isRead).length)

  const initNotificaiton = async () => {
    const t = await requestPermission()

    if (t) {
      fcmToken.value = t
      const res = await postFcmToken(t)
      console.log('postFcmToken', res)

      initMessaging((payload) => {
        messageToast(payload.data.title, payload.data.body)
        getNotifications()
      })
    }
  }

  const setUreadBadge = (unreadCount = 0) => {
    if (navigator.setAppBadge) {
      if (unreadCount && unreadCount > 0) {
        navigator.setAppBadge(unreadCount)
      } else {
        navigator.clearAppBadge()
      }
    }
  }

  // Share
  const shareText = ref('')
  const shareUrl = ref('')

  // QA
  const qaFilters = ref(JSON.parse(JSON.stringify(defaultFilter)))

  // Workshop Tool
  const tools = ref([
    { field: 'daily', link: '/daily', title: t('workshop.daily'), cover: '/images/workshop/daily.png' },
    { field: 'product', link: '/product', title: t('workshop.product'), cover: '/images/workshop/official.png' },
    { field: 'qa', link: '/qa', title: t('workshop.qa'), cover: '/images/workshop/qa.png' },
    {
      field: 'saveDeckLog',
      title: t('workshop.findDeck'),
      cover: '/images/workshop/find.png',
      content: 'PaneWorkshopFindDeck',
    },
    {
      field: 'deckLogUpload',
      title: t('workshop.uploadToDeckLog'),
      cover: '/images/workshop/server.png',
      content: 'PaneWorkshopDeckLogUpload',
    },
    { field: 'export', title: t('workshop.exportDeck'), content: 'PaneWorkshopExport', cover: '/images/workshop/report.png' },
    {
      field: 'buildCard',
      title: t('workshop.buildCard'),
      content: 'PaneWorkshopBuildCard',
      cover: '/images/workshop/12card.png',
    },
    {
      field: 'cardDetection',
      title: t('workshop.cardDetection'),
      content: 'PaneWorkshopCardDetection',
      cover: '/images/workshop/cardDetection.jpg',
      isHero: true,
    },
  ])

  // ajax
  const getUser = async () => {
    const { data } = await useFetch(api['user'](), {
      key: 'user',
      retry: 3,
      retryDelay: 500,
    })
    if (data.value) {
      user.value = data.value
    }
  }

  const updateUser = async (body) => {
    const res = await doFetch({
      type: 'userUpdate',
      method: 'PUT',
      body,
      options: { silent: true },
    })

    if (res) {
      await getUser()
    }
  }

  const updateUserHeroCode = async (body) => {
    const res = await doFetch({
      type: 'heroCode',
      method: 'PUT',
      body,
      options: { silent: true },
    })

    if (res) {
      await getUser()
    }
    return res
  }

  const deleteUser = async () => {
    const res = await doFetch({
      type: 'user',
      method: 'DELETE',
    })

    if (res) {
      messageSuccess(`${t('ajax.delete')} ${t('ajax.success')}`)
    }

    return res
  }

  const postFcmToken = async (token) => {
    return await doFetch({
      type: 'fcmToken',
      method: 'POST',
      body: { token },
      options: { silent: true },
    })
  }

  const getNotifications = async () => {
    const { data } = await useFetch(api['notification'](), {
      key: 'notifications',
    })
    if (data.value) {
      notifications.value = data.value || []
      setUreadBadge(noReadCount.value)
    }
  }

  const readNotification = async (id) => {
    const res = await doFetch({
      type: 'readNotification',
      method: 'POST',
      body: { id },
      options: { silent: true },
    })

    if (res) {
      await getNotifications()
    }
  }

  const getBlocks = async () => {
    const { data } = await useFetch(api['block'](), {
      key: 'block',
      retry: 3,
      retryDelay: 500,
    })
    if (data.value) {
      blocks.value = data.value
    }
  }

  return {
    user,
    getUser,
    updateUser,
    updateUserHeroCode,
    deleteUser,

    blocks,
    getBlocks,

    member,

    shareText,
    shareUrl,

    notifications,
    noReadCount,
    fcmToken,
    initNotificaiton,
    getNotifications,
    readNotification,

    tools,

    qaFilters,
  }
})
