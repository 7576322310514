import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import {
  getAuth,
  getIdToken,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  deleteUser,
} from 'firebase/auth'

const providers = {
  google: GoogleAuthProvider,
  apple: OAuthProvider,
  facebook: FacebookAuthProvider,
  twitter: TwitterAuthProvider,
}

export default () => {
  // Initialize Firebase
  const auth = ref()
  const app = ref()
  const messaging = ref()
  const config = useRuntimeConfig()

  // const { isIos, isSafari } = useDevice()

  const tokenCookie = useCookie('token', {
    maxAge: 60 * 60 * 24 * 30, // 1 month
    sameSite: true,
    secure: true,
  })

  const loginEmail = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth.value, email, password)
      // Signed in
      const user = userCredential.user
      // console.log('Signed in', user)
      const idToken = await getIdToken(user)

      // storage fro middleware detection
      tokenCookie.value = idToken
      return user
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      // console.log('Error', errorMessage)
      return { status: 'error', message: errorMessage }
    }
  }

  const registerEmail = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth.value, email, password)
      // Signed in
      const user = userCredential.user
      // console.log('Signed in', user)
      const idToken = await getIdToken(user)

      // storage fro middleware detection
      tokenCookie.value = idToken
      return user
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      // console.log('Error', errorMessage)
      return { status: 'error', message: errorMessage }
    }
  }

  const loginOauth = async (type) => {
    const thisOauthProvider = providers[type.toLocaleLowerCase()]

    try {
      const provider = new thisOauthProvider(type.toLocaleLowerCase() === 'apple' ? 'apple.com' : null)

      // if (isIos && isSafari) {
      //   return signInWithRedirect(auth.value, provider)
      // }

      const result = await signInWithPopup(auth.value, provider)

      const credential = thisOauthProvider.credentialFromResult(result)
      const token = credential.accessToken
      const user = result.user

      // console.log('check oauth currentUser', auth.value.currentUser, auth.value)
      const idToken = await getIdToken(user)

      // storage fro middleware detection
      tokenCookie.value = idToken

      // console.log('Signed in', credential, result, idToken)
      return user
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      // const email = error.customData.email;
      const credential = thisOauthProvider.credentialFromError(error)
      // console.log('Error', errorMessage)
      return { status: 'error', message: errorMessage }
    }
  }

  const getRedirectUser = async (type) => {
    const thisOauthProvider = providers[type.toLocaleLowerCase()]

    try {
      const result = await getRedirectResult(auth.value)

      const credential = thisOauthProvider.credentialFromResult(result)
      const token = credential.accessToken
      const user = result.user

      // console.log('check oauth currentUser', auth.value.currentUser, auth.value)
      const idToken = await getIdToken(user)

      // storage fro middleware detection
      tokenCookie.value = idToken

      // console.log('Signed in', credential, result, idToken)
      return user
    } catch (error) {
      const errorCode = error.code
      const errorMessage = error.message
      // const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error)
      // console.log('Error', errorMessage)
      return { status: 'error', message: errorMessage }
    }
  }

  const logout = async () => {
    try {
      await signOut(auth.value)

      // storage fro middleware detection
      tokenCookie.value = null

      return true
    } catch (error) {
      return false
    }
  }

  const deleteUserAccount = async () => {
    try {
      // await signOut(auth.value)
      await deleteUser(auth.value.currentUser)

      // storage fro middleware detection
      tokenCookie.value = null

      return true
    } catch (error) {
      console.log('error', error)
      return false
    }
  }

  const getIdTokenPromise = () => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(auth.value, (user) => {
        unsubscribe()
        if (user) {
          getIdToken(user).then(
            (idToken) => {
              resolve(idToken)
            },
            (error) => {
              resolve(null)
            }
          )
        } else {
          resolve(null)
        }
      })
    })
  }

  const refreshToken = async () => {
    const token = await getIdTokenPromise()
    // console.log('refresh')
    tokenCookie.value = token
  }

  const initMessaging = (fn) => {
    onMessage(messaging.value, (payload) => {
      // console.log('[firebase-messaging] Received message ', payload)
      if (payload.data && payload.data.title) {
        fn(payload)
      }
    })
  }

  const getMessagingToken = () => {
    return new Promise((resolve, reject) => {
      getToken(messaging.value, { vapidKey: config.public.firebaseMessagingToken })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // console.log('fcmToken ', currentToken)
            resolve(currentToken)
          } else {
            // Show permission request UI
            // console.log('No registration token available. Request permission to generate one.')
            resolve(null)
          }
        })
        .catch((err) => {
          // console.log('An error occurred while retrieving token. ', err)
          resolve(null)
        })
    })
  }

  const requestPermission = () => {
    // console.log('Requesting permission...')
    return new Promise((resolve, reject) => {
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          // console.log('Notification permission granted.')
          resolve(await getMessagingToken())
        } else {
          // console.log('Unable to get permission to notify.')
          resolve(null)
        }
      })
    })
  }

  onBeforeMount(() => {
    app.value = initializeApp(config.public.firebaseConfig)
    auth.value = getAuth(app.value)
  })

  onMounted(() => {
    messaging.value = getMessaging(app.value)
  })

  return {
    loginEmail,
    loginOauth,
    registerEmail,
    logout,
    refreshToken,
    getMessagingToken,
    initMessaging,
    requestPermission,

    getRedirectUser,
    deleteUserAccount,
  }
}
