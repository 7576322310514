export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return
  const nuxtApp = useNuxtApp()
  if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return

  const { nowCard } = storeToRefs(useCardStore())
  const { showPane } = storeToRefs(usePreferenceStore())

  if (nowCard.value) {
    nowCard.value = null
    return abortNavigation()
  }

  if (showPane.value) {
    showPane.value = null
    return abortNavigation()
  }
})
