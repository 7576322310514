<template>
  <section class="grid place-content-center bg-base h-screen w-screen backdrop-blur">
    <div class="flex flex-col gap-2 items-center p-4">
      <img class="h-60 w-60 mb-8" src="/images/status/error.png" alt="" />
      <h2 class="text-zinc-100 font-bold text-5xl">{{ $t('empty') }}</h2>
      <p class="text-center text-zinc-400">{{ $t('emptyInfo') }}</p>
      <span class="text-zinc-400">---</span>
      <span class="text-zinc-400">{{ error.message }}</span>
      <button class="rounded-xl bg-zinc-600/50 hover:bg-zinc-600 text-white font-bold px-2 py-1" @click="handleError">
        <span>回到首頁</span>
      </button>

    </div>
  </section>
</template>
  
<script setup>
const { error } = defineProps({
  error: Object
})

const handleError = () => clearError({ redirect: '/' })
</script>