export const colors = [
  '#ef4444',
  '#f97316',
  '#f59e0b',
  '#eab308',
  '#84cc16',
  '#22c55e',
  '#10b981',
  '#14b8a6',
  '#06b6d4',
  '#0ea5e9',
  '#3b82f6',
  '#6366f1',
  '#8b5cf6',
  '#a855f7',
  '#d946ef',
  '#ec4899',
  '#f43f5e',
]

export const cardColors = {
  紅: '#ef4444',
  藍: '#3b82f6',
  綠: '#22c55e',
  黃: '#eab308',
  紫: '#a855f7',
  default: '#121212',
}

export const cardColorsEN = {
  red: '#ef4444',
  blue: '#3b82f6',
  green: '#22c55e',
  yellow: '#eab308',
  purple: '#a855f7',
  default: '#121212',
}

export const translateColor = {
  紅: 'red',
  藍: 'blue',
  綠: 'green',
  黃: 'yellow',
  紫: 'purple',
}
