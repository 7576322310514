import { defaultCardTagInfo, defaultFilterData, defaultFilter } from '~/constants/series'
import { api } from '~/constants/api'

export const useCardStore = defineStore('card', () => {
  const { doFetch } = useFetchApi()

  // card
  const cardCollections = ref([]) // 判斷卡片關聯用
  const nowCard = ref() // 當前卡片
  const cardSet = ref([]) // 當前卡片篩選後的集合

  const prevCard = computed(() => {
    const index = cardSet.value.findIndex((e) => e.id === nowCard.value.id)
    if (index === -1 || index === 0) return null
    else return cardSet.value[index - 1]
  })
  const nextCard = computed(() => {
    const index = cardSet.value.findIndex((e) => e.id === nowCard.value.id)
    if (index === -1 || index === cardSet.value.length - 1) return null
    else return cardSet.value[index + 1]
  })

  // filter
  const filters = ref(JSON.parse(JSON.stringify(defaultFilterData)))
  const cardFilters = ref(JSON.parse(JSON.stringify(defaultFilter)))
  const filterCount = computed(
    () =>
      Object.keys(cardFilters.value)
        .filter((e) => !['keywordRule'].includes(e))
        .map((e) => cardFilters.value[e])
        .flat()
        .filter((e) => e).length
  )
  const resetFilter = () => {
    filters.value = JSON.parse(JSON.stringify(defaultFilterData))
    cardFilters.value = JSON.parse(JSON.stringify(defaultFilter))
  }
  const clearCardFilters = () => {
    cardFilters.value = JSON.parse(JSON.stringify(defaultFilter))
  }
  const updateCardFilters = (filter) => {
    cardFilters.value = JSON.parse(JSON.stringify(filter))
  }

  // filterTopics
  const filterTopics = ref([
    { icon: 'StarIcon', name: '常用', field: 'favorite', collapse: false },
    { icon: 'MagnifyingGlassIcon', name: '關鍵字', field: 'keyword', collapse: false },
    { icon: 'AdjustmentsVerticalIcon', name: '排序', field: 'sort', collapse: false },
    { icon: 'FunnelIcon', name: '類型', field: 'type', collapse: false },
    { icon: 'FunnelIcon', name: '等級', field: 'level', collapse: false },
    { icon: 'FunnelIcon', name: '顏色', field: 'color', collapse: false },
    { icon: 'FunnelIcon', name: '費用', field: 'cost', collapse: false },
    { icon: 'FunnelIcon', name: '魂傷', field: 'soul', collapse: false },
    { icon: 'FunnelIcon', name: '攻擊力', field: 'attack', collapse: false },
    { icon: 'FunnelIcon', name: '稀有度', field: 'rare', collapse: false },
    { icon: 'FunnelIcon', name: '判定', field: 'trigger', collapse: false },
    { icon: 'FunnelIcon', name: '特徵', field: 'feature', collapse: false },
    { icon: 'FunnelIcon', name: '商品', field: 'productName', collapse: false },
  ])

  const allTopicCollapse = computed(() => filterTopics.value.reduce((a, c) => c.collapse && a, true))

  const toggleTopicCollapse = (value = '') => {
    const now = allTopicCollapse.value
    filterTopics.value = filterTopics.value.map((e) => {
      e.collapse = value === '' ? !now : value
      return e
    })
  }

  // ajax
  const getCardInfo = async (id) => {
    return await doFetch({ type: 'cardInfo', params: { id } })
  }

  return {
    nowCard,
    cardSet,
    cardCollections,
    prevCard,
    nextCard,

    filters,
    cardFilters,
    resetFilter,
    filterCount,

    filterTopics,
    allTopicCollapse,
    toggleTopicCollapse,
    clearCardFilters,
    updateCardFilters,

    getCardInfo,
  }
})
