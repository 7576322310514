import UrlPattern from 'url-pattern'

export default defineNuxtRouteMiddleware((to, from) => {
  const checks = [{ watch: ['/user'], fallback: '/login' }]

  const token = useCookie('token')

  const check = checks.find((cs) => cs.watch.find((e) => new UrlPattern(e).match(to.path)))

  if (check) {
    if (check.fallback && !token.value) {
      return navigateTo(check.fallback)
    }

    if (check.goTo && token.value) {
      return navigateTo(check.goTo)
    }
  }
})
