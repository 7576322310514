import revive_payload_client_4sVQNw7RlN from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/BottleNekoV2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/BottleNekoV2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pdfmake_client_PXHi28cJi9 from "/vercel/path0/BottleNekoV2/node_modules/nuxt-pdfmake/dist/runtime/pdfmake.client.mjs";
import pwa_client_Eorgdr12jA from "/vercel/path0/BottleNekoV2/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import composition_sLxaNGmlSL from "/vercel/path0/BottleNekoV2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/BottleNekoV2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_t2GMTTFnMT from "/vercel/path0/BottleNekoV2/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import view_transitions_client_2LpVRIemQY from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/BottleNekoV2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/vercel/path0/BottleNekoV2/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import scrollTop_B37spTga6b from "/vercel/path0/BottleNekoV2/plugins/scrollTop.js";
import vue_virtual_scroller_client_FjsjCiKWLG from "/vercel/path0/BottleNekoV2/plugins/vue-virtual-scroller.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pdfmake_client_PXHi28cJi9,
  pwa_client_Eorgdr12jA,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_t2GMTTFnMT,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  scrollTop_B37spTga6b,
  vue_virtual_scroller_client_FjsjCiKWLG
]