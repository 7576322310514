export const api = {
  partner: () => '/api/home/partner',
  product: () => '/api/product',
  series: () => '/api/series',
  seriesId: (id) => `/api/series/${id}`,
  card: (id) => `/api/card/${id}`,
  cardInfo: () => `/api/card/info`,
  filter: (id) => `/api/card/filter/${id}`,
  qa: () => `/api/qa/card`,
  qas: () => `/api/qa`,
  cardPriceHistory: () => `/api/price/history`,
  comment: (mode) => `/api/comment/${mode}`,
  commentLike: () => `/api/comment/like`,
  commentUpdate: () => `/api/comment/update`,
  commentDelete: () => `/api/comment/delete`,
  commentAdd: (mode) => `/api/comment/${mode}`,
  user: () => '/api/auth/user',
  userInfo: () => '/api/auth/info',
  userUpdate: () => '/api/auth/info',
  userPreference: () => '/api/auth/preference',
  decks: () => '/api/decks',
  socials: () => '/api/socials',
  mySocial: () => '/api/social/my',
  addSocial: () => '/api/social/add',
  socialCode: (code) => `/api/social/${code}`,
  deleteSocial: (code) => `/api/social/${code}`,
  putSocial: (code) => `/api/social/${code}`,
  socialTopic: (code) => `/api/social/topic/${code}`,
  deckCode: (code) => `/api/deck/${code}`,
  deckPublic: () => `/api/deck/public`,
  addDeck: () => '/api/deck/add',
  deleteDeck: (code) => `/api/deck/${code}`,
  putDeck: (code) => `/api/deck/${code}`,
  deckDeckLogCode: () => `/api/deck/decklog`,
  image: () => `/api/image`,
  fcmToken: () => `/api/fcm/register`,
  notification: () => `/api/notification`,
  readNotification: () => `/api/notification/read`,
  homeTopic: () => `/api/home/topic`,
  dailys: () => `/api/dailys`,
  daily: () => `/api/daily`,
  topics: () => '/api/topics',
  homeLanding: () => `/api/home/landing`,
  member: (id) => `/api/member/${id}`,
  deckLog: (code) => `/api/decklog/${code}`,
  deckLogAccess: () => `/api/decklog/access`,
  deckLogCheck: () => `/api/decklog/check`,
  deckLogPublish: () => `/api/decklog/publish`,
  report: () => `/api/report`,
  block: () => `/api/block`,
  blockId: (id) => `/api/block/${id}`,
  priceTop: () => '/api/price/top',
  heroCode: () => '/api/auth/hero',
  cardDetection: (type = 'all') => `/api/card/detection/${type}`,
}

const decklogDomain = 'https://decklog.bushiroad.com'
const decklogEnDomain = 'https://decklog-en.bushiroad.com'

export const deckLogApi = {
  deck: (code, en) => ({
    url: `${en ? decklogEnDomain : decklogDomain}${en ? '/system/app-ja/api/view/' : '/system/app/api/view/'}${code}`,
    headers: {
      'Content-Type': 'application/json',
      Referer: `${en ? decklogEnDomain : decklogDomain}`,
    },
  }),

  access: (en) => ({
    url: `${en ? decklogEnDomain : decklogDomain}${en ? '/system/app-ja/api/create/' : '/system/app/api/create/'}`,
    headers: {
      'Content-Type': 'application/json',
      Referer: `${en ? decklogEnDomain : decklogDomain}${en ? '/ja/create?c=102' : '/create?c=2'}`,
    },
  }),

  check: (en) => ({
    url: `${en ? decklogEnDomain : decklogDomain}${en ? '/system/app-ja/api/check/102' : '/system/app/api/check/2'}`,
    headers: {
      'Content-Type': 'application/json',
      Referer: `${en ? decklogEnDomain : decklogDomain}${en ? '/ja/create?c=102' : '/create?c=2'}`,
    },
  }),

  publish: (en) => ({
    url: `${en ? decklogEnDomain : decklogDomain}${en ? '/system/app-ja/api/publish/102' : '/system/app/api/publish/2'}`,
    headers: {
      'Content-Type': 'application/json',
      Origin: `${en ? decklogEnDomain : decklogDomain}`,
      Referer: `${en ? decklogEnDomain : decklogDomain}${en ? '/ja/create?c=102' : '/create?c=2'}`,
    },
  }),
}

export const decklogWebsite = (en, code) => `${en ? decklogEnDomain : decklogDomain}${en ? '/ja/view/' : '/view/'}${code}`
