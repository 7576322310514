import * as _ from 'lodash'
import { CardZh, CardTranslate } from '~/constants/translate'
import { defaultFilter } from '~/constants/series'
import { defaultSetting } from '~/constants/preference'
import { api } from '~/constants/api'
import { SquaresPlusIcon } from '@heroicons/vue/24/outline'

const langusages = [
  { name: '繁體中文', value: 'zh' },
  { name: 'English', value: 'en' },
  { name: '日本語', value: 'jp' },
]

export const usePreferenceStore = defineStore(
  'preference',
  () => {
    const { t } = useNuxtApp().$i18n
    const { doFetch } = useFetchApi()
    const token = useCookie('token')

    const groupTabs = computed(() => [
      { name: t('類型'), field: 'type', unit: '', isClimmax: true, icon: SquaresPlusIcon },
      { name: t('顏色'), field: 'color', unit: '', isClimmax: true, icon: SquaresPlusIcon },
      { name: t('等級'), field: 'level', unit: t('等'), isClimmax: true, icon: SquaresPlusIcon },
      // { name: t('費用'), field: 'cost', unit: t('費'), isClimmax: true, icon: SquaresPlusIcon },
      { name: t('稀有度'), field: 'rare', unit: '', isClimmax: false, icon: SquaresPlusIcon },
      { name: t('商品'), field: 'productName', unit: '', isClimmax: false, icon: SquaresPlusIcon },
    ])

    const settingInfos = ref([
      // { tab: 'general', collapse: true, name: '黑暗模式', field: 'darkMode', icon: 'MoonIcon', info: '切換黑暗模式，預設 "關閉"' },
      { tab: 'general', collapse: true, name: '系統語言', field: 'systemLanguage', icon: 'GlobeAltIcon', info: '系統 UI 呈現語言，預設 "繁體中文"' },
      {
        tab: 'general',
        collapse: true,
        name: '翻譯語言',
        field: 'translation',
        icon: 'LanguageIcon',
        info: '資料內容呈現語言，預設 "開啟/繁體中文"',
      },
      { tab: 'general', collapse: true, name: '卡片顯示模式', field: 'showType', icon: 'MoonIcon', info: '切換表格/圖片模式，預設 "表格模式"' },
      { tab: 'general', collapse: true, name: '震動', field: 'vibrate', icon: 'CursorArrowRippleIcon', info: '開關震動，預設 "開啟"' },
      { tab: 'series', collapse: true, name: '卡片圖片特效', field: 'coverEffect', icon: 'SparklesIcon', info: '卡圖顯示稀有度特效，預設 “關閉”' },
      {
        tab: 'series',
        collapse: true,
        name: '卡圖滑鼠反應',
        field: 'coverFollow',
        icon: 'CubeTransparentIcon',
        info: '是否讓卡圖對滑鼠有3D反應，預設 "開啟"',
      },
      { tab: 'series', collapse: true, name: '常用篩選', field: 'filterFavorite', icon: 'StarIcon', info: '設定常用的卡片篩選' },
      { tab: 'series', collapse: true, name: '常用關鍵字', field: 'filterKeyword', icon: 'MagnifyingGlassIcon', info: '設定常用的關鍵字搜尋' },
      {
        tab: 'deck',
        collapse: true,
        name: '電腦版劇院模式',
        field: 'theaterMode',
        icon: 'TvIcon',
        info: '開啟後，會影藏牌組資訊，並專注於優化牌組顯示，電腦版限定。',
      },
      {
        tab: 'deck',
        collapse: true,
        name: '預設牌組名稱',
        field: 'deckDefaultName',
        icon: 'CodeBracketIcon',
        info: '設定預設牌組名稱，變數自動帶入',
      },

      {
        tab: 'deck',
        collapse: true,
        name: '預設牌組分群方式',
        field: 'deckDefaultGroup',
        icon: 'SquaresPlusIcon',
        info: '設定預設牌組分群方式，卡片會以分群方式顯示分組',
      },
    ])

    const setting = ref(JSON.parse(JSON.stringify(defaultSetting)))

    const systemLanguages = ref(langusages.filter((e) => ['zh', 'en', 'jp'].includes(e.value)))
    const translationLanguages = ref(langusages.filter((e) => ['zh'].includes(e.value)))
    const cardInfos = ref([
      { name: '卡圖', value: 'cover' },
      { name: 'ID', value: 'id' },
      { name: '稀有度', value: 'rare' },
      { name: '名稱', value: 'title' },
      { name: '卡片選擇', value: 'counter' },
      { name: '價格', value: 'price' },
      { name: '類型', value: 'type' },
      { name: '等級', value: 'level' },
      { name: '費用', value: 'cost' },
      { name: '魂傷', value: 'soul' },
      { name: '攻擊', value: 'attack' },
    ])

    const toggleTranslation = () => {
      setting.value.translation = !setting.value.translation
    }
    const resetSetting = (field) => {
      if (field) setting.value[field] = JSON.parse(JSON.stringify(defaultSetting[field]))
      else setting.value = JSON.parse(JSON.stringify(defaultSetting))
    }

    const showInfo = (field) => (setting.value.cardInfo ? setting.value.cardInfo?.includes(field) : true)

    const updateFilterFavorite = (filter) => {
      let input = JSON.parse(JSON.stringify(setting.value.filterFavorite))
      let lookFields = Object.keys(defaultFilter).filter((e) => !['favorite', 'keyword', 'keywordRule', 'sort'].includes(e))

      setting.value.filterFavorite = input.map((inputFilter) => {
        lookFields.forEach((field) => {
          if (inputFilter.value[field].length > 0) {
            inputFilter.value[field] = inputFilter.value[field].map((e) => {
              let newData = filter[field].find((f) => f.field === e.field)
              if (newData) return newData
              else return { field: e.field, data: [] }
            })
          }
        })

        return inputFilter
      })
    }

    const translate = (data, field) => {
      try {
        const checker = (a) => Array.isArray(a) && _.compact(a).length > 0
        const inputs = (Array.isArray(field) ? field : [field]).map((e) => `i18n.${setting.value.translationLanguage}.${e}`)

        const t = _.at(data, inputs)
        const o = _.at(data, field)

        // translation data validation
        if (setting.value.translation && checker(t)) return t.length === 1 ? t[0] : t

        // origin data validation
        if (checker(o)) return o.length === 1 ? o[0] : o

        return field
      } catch (e) {
        return field
      }
    }
    const autoTranslateZh = (string, replaceFormat) => {
      if (!string) return ''
      const zh = { ...CardTranslate, ...CardZh }
      const tokens = Object.keys(zh).sort((a, b) => b.length - a.length)
      tokens.forEach((token) => {
        string = string.replace(new RegExp(token, 'gmi'), replaceFormat.replace(/{{token}}/gim, zh[token]))
      })
      return string
    }

    const allInfoStatus = computed(() => settingInfos.value.reduce((a, c) => c.collapse && a, true))

    const toggleInfoCollapse = (value = '') => {
      const now = allInfoStatus.value

      settingInfos.value = settingInfos.value.map((e) => {
        e.collapse = value === '' ? !now : value
        return e
      })
    }

    const showPane = ref()
    const showNavPane = ref()

    // ajax

    const getPreference = async () => {
      const { data } = await useFetch(api['userPreference'](), {
        key: 'preference',
        retry: 3,
        retryDelay: 500,
      })
      if (data.value) {
        setting.value = data.value
        setTimeout(() => {
          isInit.value = true
        }, 500)
      }
    }

    const putPreference = async (body) => {
      const res = await doFetch({
        type: 'userPreference',
        method: 'PUT',
        body,
        options: { silent: true },
      })
    }

    const isInit = ref(false)
    watch(
      () => setting.value,
      (value) => {
        if (token.value && isInit.value) putPreference(value)
      },
      { deep: true }
    )

    return {
      setting,
      systemLanguages,
      translationLanguages,
      cardInfos,
      resetSetting,

      settingInfos,
      toggleInfoCollapse,
      allInfoStatus,

      groupTabs,

      toggleTranslation,
      translate,
      autoTranslateZh,
      showInfo,

      updateFilterFavorite,

      showPane,
      showNavPane,
      getPreference,
    }
  },
  {
    persist: {
      storage: persistedState.localStorage,
      paths: ['setting'],
      afterRestore: (ctx) => {
        console.log(`just restored '${ctx.store.$id}'`)
      },
    },
  }
)
