export const defaultSetting = {
  systemLanguage: 'zh',
  translationLanguage: 'zh',
  translation: false,
  showType: 'info',
  coverEffect: false,
  // cardInfo: ['cover', 'id', 'rare', 'title', 'counter', 'price', 'type', 'level', 'cost', 'soul', 'attack'],
  filterFavorite: [],
  filterKeyword: [],
  deckDefaultName: '',
  deckDefaultGroup: 'level',
  theaterMode: false,
  vibrate: true,
  coverFollow: true,
}
