export const defaultCardTagInfo = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  element: null,
}

export const defaultFilterData = {
  level: [],
  color: [],
  type: [],
  productName: [],
  cost: [],
  soul: [],
  attack: [],
  rare: [],
  feature: [],
  trigger: [],
}

export const defaultFilter = {
  favorite: [],
  keyword: '',
  keywordRule: 'AND',
  sort: [],
  level: [],
  color: [],
  type: [],
  productName: [],
  cost: [],
  soul: [],
  attack: [],
  rare: [],
  feature: [],
  trigger: [],
}
