import * as _ from 'lodash'
import { cardColors } from '@/constants/colors'
import { api } from '@/constants/api'
import { defaultCardDeckField } from '@/constants/deck'

const replacer = (key, value) => {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    }
  } else {
    return value
  }
}

const reviver = (key, value) => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value)
    }
  }
  return value
}

export const useDeckStore = defineStore(
  'deck',
  () => {
    const { messageSuccess, messageConfirm } = useSwal()
    const { t } = useNuxtApp().$i18n
    const { doFetch } = useFetchApi()

    // Deck Builder
    const swapPool = ref([])

    const cardDecks = ref(new Map())
    const lastEditCard = ref()
    const lastEditMethod = ref('')
    const cardDeckField = ref(JSON.parse(JSON.stringify(defaultCardDeckField)))

    const resetCardDeckField = () => {
      cardDeckField.value = JSON.parse(JSON.stringify(defaultCardDeckField))
    }
    const resetCardDeck = () => {
      cardDecks.value.clear()
      swapPool.value = []
    }
    const doCardDeck = (type, card) => {
      lastEditCard.value = card
      lastEditMethod.value = type

      if (type === 'add') {
        cardDecks.value.set(card.id, {
          count: (cardDecks.value.get(card.id)?.count || 0) + 1,
          card: card,
        })
      } else if (type === 'minus') {
        if (cardDecks.value.get(card.id)?.count > 1) {
          cardDecks.value.set(card.id, {
            count: cardDecks.value.get(card.id)?.count - 1,
            card: card,
          })
        } else {
          cardDecks.value.delete(card.id)
        }
      }
    }

    const setCardDeck = (cards = []) => {
      lastEditCard.value = cards[cards.length - 1]
      lastEditMethod.value = 'add'

      // cards is a flat array like [a,a,b,b,b,b,c,c]
      cardDecks.value = cards.reduce((a, c) => {
        a.set(c.id, {
          count: (a.get(c.id)?.count || 0) + 1,
          card: c,
        })
        return a
      }, new Map())
    }

    const getCountInCardDeck = (id) => cardDecks.value.get(id)?.count || 0
    const cardDeckCount = computed(() => Array.from(cardDecks.value).reduce((a, c) => c[1].count + a, 0))
    const cardDeckPrice = computed(() => Array.from(cardDecks.value).reduce((a, c) => c[1].count * (c[1].card.price?.number || 0) + a, 0))

    ////////////////////////////////
    // deck pane config

    const editMode = ref('')
    const groupCollapse = ref({})
    const allGroupOpen = computed(() => !Object.values(groupCollapse.value).reduce((a, c) => c || a, false))

    const toggleGroupCollapse = (value = '') => {
      const now = allGroupOpen.value

      Object.keys(groupCollapse.value).forEach((e) => {
        groupCollapse.value[e] = value === '' ? now : value
      })
    }

    ////////////////////////////////
    // deck page decks
    const decks = ref([])
    const deckFilters = ref({ keyword: '', sort: [{ field: 'updatedAt', order: 'desc' }] })

    // input codes is seriesCodes [], and filter decks with codes intersection
    const decksIHave = (codes) => {
      return decks.value.filter((e) => {
        return _.intersection(e.seriesCodes, codes).length > 0
      })
    }

    ////////////////////////////////
    // deck / social code page
    const nowDeck = ref({})
    const nowDeckColor = computed(() => cardColors[(nowDeck.value.colors || []).sort((a, b) => b.count - a.count)[0]?.color || 'default'])
    const nowDeckPrice = computed(() => (nowDeck.value.cards || []).reduce((a, c) => a + c.price.number, 0))

    const isSubmitSocial = ref(false)

    const socialHistory = ref([])
    const deckMode = ref(false)
    const deckNotes = ref([])

    const addHistory = ({ keyword = '', series = 0 }) => {
      if (keyword !== '' || series !== 0) {
        socialHistory.value = _.uniqWith([{ keyword, series }, ...socialHistory.value], _.isEqual).slice(0, 10)
      }
    }

    const deleteHistory = async (item) => {
      const { value } = await messageConfirm(
        t('social.delete'),
        `${t('social.deleteInfo')} "${item.keyword || '-'} / ${getSeriesName(item.series) || '-'}"`
      )
      if (value) {
        socialHistory.value = socialHistory.value.filter((history) => !_.isEqual(history, item))
      }
    }

    ////////////////////////////////
    // social topic page
    const nowTopic = ref({})

    // ajax
    const getMyDecks = async (uid) => {
      const { data } = await useFetch(api['decks'](), {
        query: { uid },
        key: 'myDeck',
      })
      if (data.value) {
        decks.value = data.value
      }
    }

    const deleteDeck = async (id) => {
      const res = await doFetch({
        type: 'deleteDeck',
        input: id,
        method: 'DELETE',
      })

      if (res) {
        messageSuccess(`${t('ajax.delete')} ${t('ajax.success')}`)
        await getMyDecks()
      }

      return res
    }

    const publicDeck = async (code, isPublic) => {
      const res = await doFetch({
        type: 'deckPublic',
        method: 'PUT',
        body: { code, isPublic },
      })

      if (res) {
        messageSuccess(`${t('ajax.update')} ${t('ajax.success')}`)
        await getMyDecks()
        refreshNuxtData('deckCode')
      }

      return res
    }

    const createDeck = async (body) => {
      const res = await doFetch({
        type: 'addDeck',
        method: 'POST',
        body,
      })

      if (res) {
        messageSuccess(t('ajax.success'))
        await getMyDecks()
      }

      return res
    }

    const putDeck = async (id, body) => {
      const res = await doFetch({
        type: 'putDeck',
        method: 'PUT',
        input: id,
        body,
      })

      if (res) {
        messageSuccess(t('ajax.success'))
        await getMyDecks()
      }

      return res
    }

    const deleteSocial = async (id) => {
      const res = await doFetch({
        type: 'deleteSocial',
        input: id,
        method: 'DELETE',
      })

      if (res) {
        messageSuccess(`${t('ajax.delete')} ${t('ajax.success')}`)
      }

      return res
    }
    const postSocial = async (body) => {
      const res = await doFetch({
        type: 'addSocial',
        method: 'POST',
        body,
      })

      if (res) {
        await messageSuccess(t('ajax.success'))
      }

      return res
    }
    const putSocial = async (id, body) => {
      const res = await doFetch({
        type: 'putSocial',
        method: 'PUT',
        input: id,
        body,
      })

      if (res) {
        await messageSuccess(t('ajax.success'))
      }

      return res
    }

    return {
      swapPool,
      cardDecks,
      doCardDeck,
      setCardDeck,
      resetCardDeck,
      getCountInCardDeck,
      cardDeckField,
      resetCardDeckField,
      cardDeckCount,
      cardDeckPrice,
      lastEditCard,
      lastEditMethod,

      groupCollapse,
      allGroupOpen,
      toggleGroupCollapse,
      editMode,

      decks,
      deckFilters,
      decksIHave,
      socialHistory,
      deckMode,
      deckNotes,
      addHistory,
      deleteHistory,

      nowDeck,
      nowDeckColor,
      nowDeckPrice,
      isSubmitSocial,

      nowTopic,

      getMyDecks,
      deleteDeck,
      publicDeck,
      createDeck,
      putDeck,
      deleteSocial,
      postSocial,
      putSocial,
    }
  },
  {
    persist: {
      storage: persistedState.localStorage,
      paths: ['cardDecks', 'cardDeckField', 'lastEditCard', 'lastEditMethod', 'socialHistory', 'deckNotes'],
      afterRestore: (ctx) => {
        console.log(`just restored '${ctx.store.$id}'`)
      },
      serializer: {
        deserialize: (e) => JSON.parse(e, reviver),
        serialize: (e) => JSON.stringify(e, replacer),
      },
    },
  }
)
